import { reactive } from 'vue';
import type { SiamUser } from './services/UserService';
import type { Country } from './services/metadata/CountryService';
import type { ContractConditions, Supplier } from './services/ContractConditionsService';
import type { PriceType } from './services/metadata/PriceTypeService';
import type { Unit } from './services/metadata/UnitService';
import type { ProductionLocation } from './services/SupplierService';
import { Settings } from './services/SettingsService';
import { LocalLanguages } from './types/LocalLanguages';
import { APIError } from './api/APIError';
import { Currency } from './services/metadata/CurrencyService';
import { PalletModel } from './services/metadata/PalletService';

type State = {
  user?: SiamUser;
  apiError?: APIError;
  settings: Settings;
  countries?: Map<string, Country>;
  currentContractConditions?: ContractConditions;
  initialCurrentContractConditions?: ContractConditions;
  contractConditionsById: Map<string, ContractConditions>;
  suppliersByIln: Map<number, Supplier>;
  units?: Map<string, Unit>;
  currencies?: Map<string, Currency>;
  priceTypes?: Map<string, PriceType>;
  productionLocations?: Map<string, ProductionLocation>;
  palletModels?: Map<string, PalletModel>;
};

const initialState: State = {
  user: undefined,
  apiError: undefined,
  settings: {
    localLanguage: LocalLanguages.German,
  },
  countries: undefined,
  currentContractConditions: undefined,
  initialCurrentContractConditions: undefined,
  contractConditionsById: new Map<string, ContractConditions>(),
  suppliersByIln: new Map<number, Supplier>(),
  units: undefined,
  currencies: undefined,
  priceTypes: undefined,
  productionLocations: undefined,
  palletModels: undefined,
};

// this Store should only be used by services to update it
export const Store = reactive(initialState);

// this useStore is intended to be used by UI components
export const useStore = (): Readonly<State> => Store;
