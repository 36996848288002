<script lang="ts" setup>
import { ScuDropdown, ScuSelectOption } from '@scu/vue';
import { SettingsService } from '../../services/SettingsService';
import { LocalLanguages } from '../../types/LocalLanguages';
import { useMsgFormatter } from '../../i18n';
import Modal from './Modal.vue';

const { t } = useMsgFormatter();

defineProps<{
  onAccept: () => void;
  onCancel: () => void;
}>();

const onChangeLanguage = (event: CustomEvent) => {
  SettingsService.setLocalLanguage(event.detail);
};
</script>
<template>
  <Modal :isVisible="true" :headline="'topBar.settings'" :onAccept="onAccept" :onCancel="onCancel">
    <template #content>
      <ScuDropdown
        fieldId="language-settings-dropdown"
        :label="t('settings.language')"
        class="settings-modal-container"
        @scuchange="onChangeLanguage"
      >
        <ScuSelectOption :value="LocalLanguages.German">Deutsch</ScuSelectOption>
        <ScuSelectOption :value="LocalLanguages.EnglishUS">English US</ScuSelectOption>
        <ScuSelectOption :value="LocalLanguages.EnglishGB">English GB</ScuSelectOption>
      </ScuDropdown>
    </template>
  </Modal>
</template>
