import { Locale, LocalLanguages } from '../types/LocalLanguages';
import { changeLocale } from '../i18n';
import { Store } from '../store';

export type Settings = {
  localLanguage: Locale;
};

const setLocalLanguage = (language: Locale) => {
  Store.settings.localLanguage = language;
  switch (language) {
    case LocalLanguages.EnglishGB:
      changeLocale('en');
      break;
    case LocalLanguages.EnglishUS:
      changeLocale('en');
      break;
    default:
      changeLocale('de');
  }
};

export const SettingsService = { setLocalLanguage };
