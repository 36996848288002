<script setup lang="ts">
import { ScuFlyOut, ScuNavBarV5, ScuNavBarActionV5, ScuTopBarV5, ScuTreeItem, ScuTreeList, ScuIcon } from '@scu/vue';
import { useMsgFormatter } from '../../i18n';
import { useRouter } from 'vue-router';
import { useStore } from '../../store';
import { UserService } from '../../services/UserService';
import { user_settings } from '@scu/icons';

const { t } = useMsgFormatter();
const store = useStore();
const router = useRouter();

const version = __VITE_APP_VERSION__;

defineProps<{
  onSettingsClick: () => void;
}>();

const onContractConditionsCreationScuClick = () => router.push(`/`);
const onItemCreationScuClick = () => (window.location.href = `https://dev.ava.psf.odj.cloud/`);
</script>

<template>
  <ScuTopBarV5 label="AVA-KKF" data-testid="test-top-bar">
    <div slot="top-bar-right">
      <ScuIcon :src="user_settings" :size="24" />
      <ScuFlyOut
        v-if="store.user !== undefined"
        :label="store.user?.profile.name"
        class="fly-out-right"
        type="top-bar-v5"
      >
        <ScuTreeList>
          <ScuTreeItem :label="t('topBar.settings')" @scuclick="onSettingsClick" />
          <ScuTreeItem :label="t('topBar.logout')" @scuclick="UserService.logout" />
        </ScuTreeList>
      </ScuFlyOut>
      <ScuFlyOut v-else label="Login" class="fly-out-right" type="top-bar-v5">
        <ScuTreeList>
          <ScuTreeItem :label="t('topBar.settings')" @scuclick="onSettingsClick" />
          <ScuTreeItem :label="t('topBar.login')" @scuclick="UserService.login" />
        </ScuTreeList>
      </ScuFlyOut>
    </div>
  </ScuTopBarV5>
  <ScuNavBarV5 :versionLabel="version" id="kkf-navbar">
    <div slot="actions">
      <ScuNavBarActionV5 :onScuclick="onItemCreationScuClick" :label="t('topBar.navigation.itemCreation')" active />
      <ScuNavBarActionV5
        :onScuclick="onContractConditionsCreationScuClick"
        :label="t('topBar.navigation.contractConditionsCreation')"
        active
      />
    </div>
  </ScuNavBarV5>
</template>
