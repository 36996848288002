import { APIError } from '../api/APIError';
import { Store } from '../store';

// TODO SIP-PMDRI0: Implement different handlers for error modal, toast or even silent catch

const catchAPIError = (err: APIError) => {
  Store.apiError = err;
  console.error(err.message);
};
const dismissError = () => (Store.apiError = undefined);

export const ErrorService = {
  catchAPIError,
  dismissError,
};
