<script setup lang="ts">
import { ref } from 'vue';
import TopBar from './ui/shared/TopBar.vue';
import SettingsModal from './ui/shared/SettingsModal.vue';
import ErrorModal from './ui/shared/ErrorModal.vue';

const isSettingsModalVisible = ref(false);
const openSettingsModal = () => {
  isSettingsModalVisible.value = true;
};
const closeSettingsModal = () => {
  isSettingsModalVisible.value = false;
};
</script>

<template>
  <div scu-theme="lidl">
    <TopBar :onSettingsClick="openSettingsModal" />
    <router-view />
    <SettingsModal v-if="isSettingsModalVisible" :onAccept="closeSettingsModal" :onCancel="closeSettingsModal" />
    <ErrorModal />
  </div>
</template>
