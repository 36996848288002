import { config } from '../config';
import { Store } from '../store';
import { Log, Logger, User, UserManager } from 'oidc-client-ts';

let userManager: UserManager | undefined;

export type SiamUser = User & {
  profile: {
    fullName?: string;
    groupMembership?: string[];
  };
};

const getUserManager = () => {
  if (!userManager) {
    Log.setLogger(console);
    Log.setLevel(config.auth.logLevel);

    userManager = new UserManager({
      authority: config.auth.authority,
      client_id: config.auth.clientId,
      redirect_uri: config.auth.redirectUri,
      loadUserInfo: true,
      automaticSilentRenew: config.auth.useSilentRefresh,
      // 'openid' scope is required to receive the id_token - required for signout with redirect - as part of login response
      scope: 'openid profile psf roles',
      response_type: 'code',
      extraTokenParams: {
        // this affects that the access token is not encrypted, so it includes user information that the backend can use without decrypting it
        // resourceServer: 'IdentityProviderRSUE',
      },
    });
  }
  return userManager;
};

const login = async (currentRoute = '/') => {
  if (config.auth.disabled) return;

  await loadStoredUser();
  if (Store.user != null) return; // If a user is already defined inside the UserManager no login is needed

  const um = getUserManager();

  try {
    // If no user is found, the redirect callback is executed to check if the session already contains the response from a login redirect
    const _user = await um.signinRedirectCallback();
    Store.user = _user;
    return _user.state as string;
  } catch {
    // If no login was attempted beforehand a login redirect to SIAM is executed.
    await um.signinRedirect({ state: currentRoute });
  }
};

const loadStoredUser = async () => {
  if (config.auth.disabled) return;
  const um = getUserManager();
  try {
    Store.user = (await um.getUser()) ?? undefined;
  } catch (error) {
    Logger.warn('An error occurred while loading the stored user. Logging out.');
    logout();
  }
};

const logout = async () => {
  const um = getUserManager();
  um.removeUser();
  const id_token = Store.user?.id_token;
  Store.user = undefined;
  if (id_token) {
    await um.signoutRedirect({ post_logout_redirect_uri: config.auth.redirectedLogoutUrl, id_token_hint: id_token });
  } else {
    Logger.warn('No sign out redirect because there is no user.');
  }
};

export const UserService = { login, loadStoredUser, logout };
