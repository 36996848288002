import { isRef, nextTick } from 'vue';
import type { I18n } from 'vue-i18n';
import { createI18n as createI18nOriginal, useI18n as useI18nOriginal } from 'vue-i18n';
import datetimeFormats from './datetime-formats';
import de from './locales/de.json';
import en from './locales/en.json';
import numberFormats from './number-formats';
import type { MessageFormatter } from './types';
import { APIBasics } from '../api/APIBasics';

let i18n: I18n;
let supportedLocales: Set<string>;

export type MessageSchema = typeof de;

// Object containing all the supported languages and their translations.
const messages: Record<string, MessageSchema> = { de, en };

// Wrapper for the function createI18n that sets some defaults
export type I18nFactoryOptions = { locales: Record<string, [string, string]>; fallbackLocale: string };
export const createI18n = ({ locales, fallbackLocale }: I18nFactoryOptions) => {
  supportedLocales = new Set(Object.keys(locales));

  i18n = createI18nOriginal<[MessageSchema], string>({
    legacy: false,
    locale: fallbackLocale,
    fallbackLocale,
    datetimeFormats,
    numberFormats,
    messages,
  });

  return i18n;
};

export const changeLocale = async (locale: string) => {
  if (!i18n) {
    throw new Error('Locale was changed before initialization of i18n');
  }

  if (i18n.global.locale === locale || !supportedLocales.has(locale)) return;

  const loadedMessages = await loadLocalMessages(locale);
  i18n.global.setLocaleMessage(locale, loadedMessages);

  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else if (isRef(i18n.global.locale)) {
    i18n.global.locale.value = locale;
  }
  await nextTick();

  APIBasics.addOrReplaceExtraHeader('Accept-Language', locale);
  document.querySelector('html')?.setAttribute('lang', locale);
};

/**
 * Lazily loads the translations for a locale
 * @param locale the name identifier of the locale used as key inside config
 * @returns translations file the file under src/i18n/locales that should be loaded to translate the keys
 */
export const loadLocalMessages = async (locale: string): Promise<MessageSchema> => {
  switch (locale) {
    case 'de':
      return messages.de;
    case 'en':
      return messages.en;
    default:
      return messages.de;
  }
};

/**
 * Finds the preferred language of the user that is supported
 * @returns the language code of the first language inside the users preferred languages that is supported, fallbacks to 'de'
 */
export const getUserPreferredLocale = () => {
  return navigator.languages.find((lang) => supportedLocales.has(lang)) ?? 'de';
};

export const originalI18n = () => {
  return useI18nOriginal<{ message: MessageSchema }, string>({
    inheritLocale: true,
    numberFormats,
    datetimeFormats,
    messages,
  });
};

export const useMsgFormatter = (): { t: MessageFormatter } => {
  const { t } = originalI18n();
  const fmt: MessageFormatter = (...params: Parameters<MessageFormatter>) => {
    const msg = params[0];
    const msgId = typeof msg === 'string' ? msg : msg.id;
    const msgValues = typeof msg === 'string' ? undefined : msg.values;
    if (msgId) {
      if (msgValues) return t(msgId, msgValues);
      else return t(msgId);
    }
    // eslint-disable-next-line
    return (t as any)(...params);
  };
  return { t: fmt };
};
