import { NavigationGuard } from 'vue-router';
import { useStore } from '../store';

export type TokenObj = {
  aud: string[];
  domain: string;
};

// Factory function for the authorization guard
export const createAuthorizationGuard = () => {
  const guard: NavigationGuard = async (to) => {
    const { requiresAuth, requiredRoles, requiredAuds, isAuthorized } = to.meta;
    const user = useStore().user;

    // If the user is not logged in and the route is protected stop navigation immediately
    if (requiresAuth && !user) {
      return { path: '/error', query: { requestedPath: to.path } };
    }

    // Let the user pass if auth is not required or no authorization roles are set
    if (!requiresAuth || !(requiredRoles || isAuthorized)) {
      return true;
    }

    // Matching the required roles with the users groupMemberships
    const roles = user?.profile.groupMembership ?? [];
    const userHasAnyRequiredRole = ((requiredRoles as string[]) ?? []).some((requiredRole: string) =>
      roles.some((role) => role.includes(requiredRole))
    );

    // audience validation
    const part = user?.access_token?.split('.')[1] ?? '';
    let decoded;
    try {
      decoded = window.atob(part);
    } catch (e) {
      // TODO: Show global error toast
      return { path: '/error', query: { requestedPath: to.path } };
    }
    let token: TokenObj;
    try {
      token = JSON.parse(decoded);
    } catch (e) {
      // TODO: Show global error toast
      return { path: '/error', query: { requestedPath: to.path } };
    }
    const userHasRequiredAud = (requiredAuds as string[]).every((requiredAud) => token.aud.includes(requiredAud));

    if (userHasAnyRequiredRole && userHasRequiredAud) {
      return true;
    }

    return { path: '/error', query: { requestedPath: to.path } };
  };

  return guard;
};
