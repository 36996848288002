<script lang="ts" setup>
import Modal from './Modal.vue';
import { useStore } from '../../store';
import { ErrorService } from '../../services/ErrorService';
import { useMsgFormatter } from '../../i18n';

const { t } = useMsgFormatter();

// TODO SIP-PMDRI0: Implement more customizable error modals
</script>
<template>
  <Modal
    v-if="useStore().apiError"
    :headline="t('error.general')"
    :onAccept="ErrorService.dismissError"
    :onCancel="() => {}"
    :hideCancelButton="true"
    acceptButtonLabel="ok"
  >
    <template #content>
      <p>{{ t('error.gateway.timeout') }}</p>
    </template>
  </Modal>
</template>
