import './assets/index.scss';

import { config } from './config';
import { createApp } from 'vue';
import { applyPolyfills, defineCustomElements } from '@scu/core-ui/dist/loader';
import App from './App.vue';
import { changeLocale, createI18n, getUserPreferredLocale } from './i18n';
import { createRouter, createWebHistory } from 'vue-router';
import { createLoginGuard } from './router/loginGuard';
import { createAuthorizationGuard } from './router/authorizationGuard';
import { routes } from './router';
import { SettingsService } from './services/SettingsService';
import { LocalLanguages } from './types/LocalLanguages';

(async () => {
  // Initializing CoreUI
  await applyPolyfills();
  await defineCustomElements();

  // Initializing i18n
  const i18n = createI18n(config.i18n);
  const locale = getUserPreferredLocale();
  await changeLocale(locale ?? config.i18n.fallbackLocale);
  SettingsService.setLocalLanguage(locale != 'de' ? LocalLanguages.EnglishUS : LocalLanguages.German);

  const router = createRouter({
    history: createWebHistory(config.env.baseUrl),
    routes,
  });

  const app = createApp(App).use(router).use(i18n);

  router.beforeEach(createLoginGuard());
  router.beforeEach(createAuthorizationGuard());
  await router.isReady();

  app.mount('#root');
})();
